import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout'
import ReactPlayer from 'react-player'
import './products.css'
import CatcherVid from '../../images/wr-catcher-vid.mp4'


const Products = () => ( 
<Layout>
    <div class="product-content">	
        <div class="product-section-two-wrapper">
           
           
            <div className="product-card-wrapper">
                <div className="product-card-top-wrapper">
                    <h2 className="product-title">Panel Assembly Robot</h2>
                    <div className="product-card-description">
                    Williams Robotics is developing robotic systems that build homes. Our first product constructs stud walls.  The robot can be deployed to Structural Building Component manufacturers.  It can also be configured to operate at the home site to produce stud walls on demand in a “stick built” fashion.  A functional prototype of the system is up and running in Burlington, North Carolina.
                                It will be a game changer.	
                    </div>
                </div>
                <div className="product-card-two-grid">
                    <div className="product-video">
                            <ReactPlayer 
                            url='https://tomrau.wistia.com/medias/t2z3gcfjxa'
                            className='react-player'
                            playing
                            width='100%'
                            height='100%'
                            pip={false}
                            playbackRate={1}
                            playing={false}
                            playsinline={false}
                            progressInterval = {1000}
                            />
                    </div>
                    <div class="features-list-wrapper">
                            <h3>Features</h3>
                            <ul class="product-features">
                                        <li>1 operator</li>
                                        <li>2 wall-feet per minute</li>
                                        <li>16' x 9' stud wall capable</li>
                                        <li>Builds rough openings in place</li>
                                        <li>Automatically reconfigurable clamping system</li>
                                        <li>Automatically reprogrammed robot guidance</li>		
                            </ul>
                            <p className="patent-info"> 
                        Covered by patent numbers 10,189,176,  8,185,240, 8,606,399,  and 591,336 (NZ), 2,735,144(CA), 2009285754(AU), 2621691(EP)											</p>
                    <p className="patent-info">* All specifications subject to change at manufacturer’s discretion.</p>
                   
                    </div>
                </div>
            </div>

            <div className="product-card-wrapper">
                <div className="product-card-top-wrapper">
                    <h2 className="product-title">Catcher Robot</h2>
                    <div className="product-card-description">
                    Our new component catcher robot frees-up one to two “catchers” to do more valuable work. It catches, centers, and stacks high-count runs at rates up to 60 boards a minute. It can be retro-fit to existing component saws and includes simple stack setup and status feed-back to the sawyer. Future enhancements will enable it to stack truss components from 2 to 16 feet into complex cart configurations.
                    </div>
                </div>
                <div className="product-card-two-grid">
                    <div className="product-video">
                            <ReactPlayer 
                            url="https://tomrau.wistia.com/medias/p8e2akkkt1"
                            className='react-player'
                            playing
                            width='100%'
                            height='100%'
                            pip={false}
                            playbackRate={1}
                            playing={false}
                            playsinline={false}
                            progressInterval = {1000}
                            />
                    </div>
                    <div class="features-list-wrapper">
                            <h3>Features</h3>
                            <ul class="product-features">
                                        <li>2 foot to 16 foot board length</li>
                                        <li>Designed to fit within existing workflows
</li>
                                        <li>Independent operation while stacking.</li>
                                        <li>Simple operator interface</li>
                                        <li>Multiple stack patterns </li>
                                        <li>Board indexing an queuing</li>	
                                        <li>Customizable layout and design</li>		
                                        <li>Contact us to learn more</li>		
                            </ul>
                        
                    <p className="patent-info">* All specifications subject to change at manufacturer’s discretion.</p>
                   
                    </div>
                </div>
            </div>
            
                   
                   
                   
                   
                   
                   
                   
                   
                   
                   
                   
                   
                   


		
		
		</div>
	</div>
    </Layout>
    )   
    export default Products